<template>
  <validation-observer v-slot="{ invalid }" class="container">
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="required"
    >
      <div class="control">
        <label class="label">Operating Mode</label>
        <div class="select">
          <select v-model="ic.mode" name="Mode">
            <option :value="null">Please select a mode</option>
            <option value="PAYMENT">Payment</option>
            <option value="GUEST">Guest</option>
          </select>
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:1024"
      v-if="ic.mode == 'GUEST'"
    >
      <div class="control">
        <label class="label">External URL</label>
        <input
          type="text"
          class="input"
          placeholder="e.g. https://www.example.com"
          v-model="ic.externalURL"
          name="External URL"
        />
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </div>
    </validation-provider>
    <validation-provider
      tag="div"
      class="field"
      v-slot="{ errors, failed }"
      rules="max:1024"
      v-if="ic.mode == 'GUEST'"
  >
    <div class="control">
      <label class="label">Other Amount Warning</label>
      <input
          type="text"
          class="input"
          placeholder="e.g. Paying less than the minimum amount may result in a default"
          v-model="ic.guestOtherAmountMessage"
          name="Other Amount Warning"
      />
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </div>
  </validation-provider>
    <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000000"
        v-if="ic.mode == 'GUEST'"
    >
      <div class="control">
        <label class="label">Confirm Message</label>
        <textarea
            class="textarea"
            placeholder="e.g. Payments not received by the due date my cause the payment plan to default."
            v-model="ic.guestConfirmMessage"
            name="Confirm Message"
        ></textarea>
      </div>
      <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
    </validation-provider>
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      ic: {
        instanceId: null,
        mode: "PAYMENT",
        externalURL: null,
        guestOtherAmountMessage: null,
        guestConfirmMessage: null
      }
    };
  },
  computed: {
    ...mapState(["instanceConfig"])
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPaymentPortalInstanceConfig(to.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm, to.params.id);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceMain; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView(to.params.id);
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    loadView(id) {
      this.ic.instanceId = id;
      this.ic.externalURL = this.instanceConfig.externalURL;
      this.ic.mode = this.instanceConfig.mode;
      this.ic.guestOtherAmountMessage = this.instanceConfig.guestOtherAmountMessage;
      this.ic.guestConfirmMessage = this.instanceConfig.guestConfirmMessage;
    },
    ...mapActions("inst", ["fetchInstances"]),
    ...mapActions([
      "fetchPaymentPortalGlobalConfig",
      "fetchPaymentPortalInstanceConfig",
      "saveInstanceConfig"
    ]),
    async save() {
      await this.saveInstanceConfig(this.ic);
      window.scrollTo(0, 0);
    },
    async revert() {
      this.loadView();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Instance config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="scss">
.clob {
  white-space: pre-wrap;
}
</style>
